import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 84; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/16-aug-1/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }
  return imgArray;
})();

export default function Independenceday2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Independence Day Celebrations</h1>

        <p>
          <strong>
            “A nation’s strength ultimately lies in what it can do on its own
            and not in what it can borrow from others.”
          </strong>
          <br />
          <br />
          Chinmaya Vidyalaya, New Delhi, celebrated India’s 77th Independence
          Day with great fervour and enthusiasm. The students of the Senior
          School presented an assembly that commenced with the hoisting of the
          National Flag followed by the National Anthem. Captain Ajay Kumar Bahl
          from Indian Navy, graced the occasion as the Honourable Chief Guest.
          In his motivational address, he urged the students to value the
          sacrifices made by the Indian freedom fighters who made us breathe in
          free India.The students, in their vibrant attires, presented India’s
          cultural diversity and religious unity in a cultural program.The
          Principal of the school, Ms Archana Soni, in her invigorating
          address,laid emphasis on the values of responsibility and unity. The
          cultural program in the assembly conducted by the Primary Wing
          witnessed a soulful rendition and an enthralling dance performance by
          the students. A group of little learners of Pre-Primary Wing came
          dressed as brave soldiers and freedom fighters and spoke about their
          famous slogans. Apart from a patriotic song and a dance, the children
          also enjoyed savouring the ' Tricoloured Foods' <br />
          <br />
          in their tiffins to experience the taste of India in the colours of
          India. The celebrations rekindled patriotism and reminded everyone of
          their responsibilities as citizens.
          <br />
          <br />
          Chinmaya Vidyalaya, New Delhi, wishes everyone a very Happy
          Independence Day.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
